import { createTrackedSelector } from 'react-tracked'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { createPersistentStorage } from '@/util/zustand-middleware'

interface State {
  advancedMode: boolean
}

export const defaultState = {
  advancedMode: false,
}

interface Actions {
  setAdvancedMode: (advancedMode: boolean) => void
}

type Store = State & Actions

export const useSettingsBase = create<Store>()(
  persist(
    immer((set, get) => ({
      ...defaultState,

      setAdvancedMode: (advancedMode) => set({ advancedMode }),
    })),
    {
      name: 'settings',
      storage: createPersistentStorage(),
    }
  )
)

export const useTrackedSettings = createTrackedSelector(useSettingsBase)
