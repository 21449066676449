import { useMemo } from 'react'
import Gleap from 'gleap'
import { usePostHog } from 'posthog-js/react'

import { IS_DEV } from '@/constants'
import { trpc } from '@/util/trpc'
import userRoles from '@/util/userRoles'

declare const window: any

export function useAuth() {
  const posthog = usePostHog()

  const {
    data: user,
    isLoading,
    isError,
    status,
  } = trpc.user.me.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    useErrorBoundary: false,
    onSuccess(user) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'v8thf2gu',
        name: user.name, // Full name
        email: user.email, // Email address
        created_at: new Date(), // Signup date as a Unix timestamp
        user_hash: user.hash,
      })
      if (user.id && user.Company?.id) {
        // Identify sends an event, so you want may want to limit how often you call it
        posthog?.identify(user.id, {
          email: user.email,
        })
        posthog?.group('company', user.Company?.id, {
          company: user.Company?.companyName,
        })

        if (IS_DEV) {
          Gleap.identify(user.id, {
            name: user.name,
            email: user.email,
            companyId: user.Company?.id,
            companyName: user.Company?.companyName,
          })
        }
      }
    },

    retry: (failureCount, error) => error.data?.httpStatus !== 401, // dont retry if the error was caused by a 401 (unauthorized)
  })
  const isLoggedIn = useMemo(() => status === 'success', [status])
  const isAdmin = useMemo(() => user?.role === userRoles.superAdmin, [user?.role])
  return {
    isLoggedIn,
    user,
    isProfileLoading: isLoading,
    isError,
    isAdmin,
  }
}
