import React, { SVGProps, useEffect, useMemo, useRef, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/solid'
import {
  Button,
  Checkbox,
  NumberInput,
  Popover,
  Radio,
  RangeSlider,
  Rating,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { useClickOutside, useDidUpdate } from '@mantine/hooks'
import * as Sentry from '@sentry/react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { atom, useAtom } from 'jotai'
import { differenceBy, groupBy, unionBy, uniq, xor, xorBy } from 'lodash'
import { ListFilter } from 'lucide-react'
import { usePostHog } from 'posthog-js/react'
import { NodeApi, NodeRendererProps, Tree, TreeApi } from 'react-arborist'
import { useSearchParams } from 'react-router-dom'
import { SearchMd } from 'untitled-icons'

import { ErrorFallback } from '@/components/ErrorFallback'
import { DataLoader, Loader } from '@/components/Loader'
import useDebounce from '@/hooks/useDebounce'
import { classNames } from '@/util/classNames'
import { formatNumberCompact } from '@/util/formatNumber'
import { getCurrency } from '@/util/getCurrency'
import { prepEtailerName } from '@/util/prepEtailerName'
import { RouterOutput, trpc } from '@/util/trpc'
import { useTrackedSettings } from '../../_hooks/useGlobalSettings'
import {
  FILTERS_MAP,
  FilterType,
  invertedFiltersMap,
  omitFilterType,
  useTrackedFilterStore,
  useTrackedMIStore,
} from '../_hook'
import { sidebarRefAtom } from '../_layout'
import { SaveTemplatesModal } from './Templates'

const INDENT_STEP = 15
type Data = NonNullable<RouterOutput['ecom_filters']['category_tree']>[number]
type SelectedIds = NonNullable<
  RouterOutput['ecom_filters']['ingredient_groups']
>['selected_ids'][number]

function FolderArrow({ node }: { node: NodeApi<Data> }) {
  return (
    <span className="text-gray-600">
      {node.isInternal ? (
        node.isOpen ? (
          <ChevronUpIcon className="size-4" />
        ) : (
          <ChevronDownIcon className="size-4" />
        )
      ) : null}
    </span>
  )
}

function Node({ node, style }: NodeRendererProps<Data>) {
  //   const indentSize = Number.parseFloat(`${style.paddingLeft || 0}`)
  const { filter_ids } = useTrackedMIStore()

  const isChecked = useMemo(
    () =>
      filter_ids.findIndex((o) => o.id === node.data.id) > -1 ||
      filter_ids.findIndex((o) => o.id === node.parent?.data.id) > -1,
    [filter_ids, node]
  )

  const isInDeterminate =
    node.isInternal &&
    node.children?.some((n) => filter_ids.findIndex((o) => o.id === n.data.id) > -1) &&
    !isChecked

  if (node.data.name === 'LIMIT_REACHED')
    return (
      <p style={style} className="text-[10px] leading-none text-primary-500">
        List shortened. Use search bar to find specific items.
      </p>
    )

  return (
    <div style={style} className="flex items-center space-x-2">
      <Checkbox
        size="xs"
        indeterminate={isInDeterminate}
        checked={isChecked}
        className="pl-1"
        onChange={(e) => {
          e.stopPropagation()
          // node.toggle()
          if (!isChecked) {
            node.open()
          } else {
            node.close()
          }
          //   e.preventDefault()
          //   return tree.selectMulti(node.id)
        }}
      />
      <button
        className={'flex w-full items-center justify-between text-left'}
        onClick={(e) => {
          if (node.isInternal) {
            e.stopPropagation()
            node.toggle()
          }
        }}
      >
        <p className={'w-[90%] truncate text-xs hover:text-accent-600'}>
          {node.data.name}{' '}
          <span className="text-primary-500">({formatNumberCompact(node.data.count)})</span>
        </p>
        <FolderArrow node={node} />
      </button>
    </div>
  )
}

function IngredientNode({ node, style }: NodeRendererProps<Data>) {
  //   const indentSize = Number.parseFloat(`${style.paddingLeft || 0}`)
  const { filter_ids } = useTrackedMIStore()
  style = { ...style, paddingLeft: '8px' }

  const isChecked = useMemo(
    () => filter_ids.findIndex((o) => o.id === node.data.id) > -1,
    [filter_ids, node]
  )

  if (node.data.name === 'LIMIT_REACHED')
    return (
      <p style={style} className="text-[10px] leading-none text-primary-500">
        List shortened. Use search bar to find specific items.
      </p>
    )

  return (
    <div style={style} className="flex items-center space-x-2">
      {!node.isInternal && (
        <Checkbox
          size="xs"
          checked={isChecked}
          className="pl-1"
          onChange={(e) => {
            //   e.preventDefault()
            //   return tree.selectMulti(node.id)
          }}
        />
      )}
      <button
        className={'flex w-full items-center justify-between text-left'}
        onClick={(e) => {
          if (node.isInternal) {
            e.stopPropagation()
            node.toggle()
          }
        }}
      >
        <p className={'w-[90%] truncate text-xs hover:text-accent-600'}>
          {node.data.name}{' '}
          <span className="text-primary-500">({formatNumberCompact(node.data.count)})</span>
        </p>
        <FolderArrow node={node} />
      </button>
    </div>
  )
}

const useSelect = (args?: { childOnly?: boolean }) => {
  const { filterAction, setFilters } = useTrackedMIStore()
  const posthog = usePostHog()

  return (nodes: NodeApi<Data>[]) => {
    const nodeData = nodes[0]?.data

    if (!nodeData) return
    if (nodes[0]?.isInternal && args?.childOnly) return

    const parentData = nodes[0]?.parent?.data

    posthog?.capture(`${FILTERS_MAP[nodeData.id_type]} Filter Toggled`, {
      id: nodeData.id,
      name: nodeData.name,
      type: nodeData.id_type,
      parentName: parentData?.name,
      parentId: parentData?.id,
    })

    if (args?.childOnly) {
      filterAction([
        {
          id: nodeData.id,
          name: nodeData.name,
          type: nodeData.id_type,
          parentName: parentData?.name,
          parentId: parentData?.id,
          action: 'toggle',
        },
      ])
      return
    }
    if (nodes[0].isInternal) {
      const childNodes =
        nodes[0].children?.map((n) => ({
          id: n.data.id,
          name: n.data.name,
          type: n.data.id_type,
          action: 'remove' as const,
        })) ?? []
      filterAction([
        ...childNodes,
        { id: nodeData.id, name: nodeData.name, type: nodeData.id_type, action: 'toggle' },
      ])
      return
    }

    const children = nodes[0].parent?.children ?? []
    setFilters((state) => {
      const isParentChecked = state.filter_ids.findIndex((o) => o.id === parentData?.id) > -1
      if (isParentChecked && parentData) {
        const childNodes =
          children.map((n) => ({
            id: n.data.id,
            name: n.data.name,
            type: n.data.id_type,
            parentName: parentData?.name,
            parentId: parentData?.id,
          })) ?? []

        const added = unionBy(state.filter_ids, childNodes, (o) => o.id)

        const removed = differenceBy(
          added,
          [
            {
              id: parentData.id,
              name: parentData.name,
              type: parentData.id_type,
            },
            {
              id: nodeData.id,
              name: nodeData.name,
              type: nodeData.id_type,
              parentName: parentData?.name,
              parentId: parentData?.id,
            },
          ],
          (o) => o.id
        )

        state.filter_ids = removed
        return
      }

      const toggled = xorBy(
        state.filter_ids,
        [
          {
            id: nodeData.id,
            name: nodeData.name,
            type: nodeData.id_type,
            parentName: parentData?.name,
            parentId: parentData?.id,
          },
        ],
        (o) => o.id
      )

      // all the child selected
      if (children?.every((n) => toggled.findIndex((o) => o.id === n.data.id) > -1) && parentData) {
        const removed = differenceBy(state.filter_ids, toggled, (o) => o.id)
        const added = unionBy(
          removed,
          [
            {
              id: parentData.id,
              name: parentData.name,
              type: parentData.id_type,
            },
          ],
          (o) => o.id
        )
        state.filter_ids = added
        return
      }

      state.filter_ids = toggled
    })
  }
}

function mergeArrays(...dataArrays: any[][]): any[] {
  // Flatten the array of data arrays into a single array
  const allData = dataArrays.flat()

  // Use a Map to merge objects by id
  const mergedDataMap = allData.reduce((acc, item) => {
    // If the item's id already exists in the accumulator, merge them
    if (acc.has(item.id)) {
      acc.set(item.id, { ...acc.get(item.id), ...item })
    } else {
      // Otherwise, add the item to the accumulator
      acc.set(item.id, item)
    }
    return acc
  }, new Map())

  // Convert the Map values back into an array
  return Array.from(mergedDataMap.values())
}

function mergeData(data: any[], additionalData: any[]): any[] {
  return data.map((item) => {
    const additionalItem = additionalData.find((additional) => additional.id === item.id)
    return { ...item, ...additionalItem }
  })
}

// push selected nodes to the top
function addSelectedToData(data: any[], filterIds: FilterType[]): any[] {
  const selectedNodes: Data[] = []
  const unselectedNodes: Data[] = []
  data.forEach((node) => {
    if (filterIds.findIndex((o) => o.id === node.id) > -1) {
      selectedNodes.push(node)
    } else {
      unselectedNodes.push(node)
    }

    if (node.children) {
      node.children = addSelectedToData(node.children, filterIds)
    }
  })

  return [...selectedNodes, ...unselectedNodes]
}

export const CategoryTree = Sentry.withErrorBoundary(
  () => {
    const { filter_ids } = useTrackedMIStore()
    const vars = useTrackedFilterStore()
    const onSelect = useSelect()

    const { data, isLoading } = trpc.ecom_filters.category_tree.useQuery(
      omitFilterType(vars, 'Categories & Product Types'),
      {
        select(data) {
          // add isSelected recursively to data
          return (data?.map((node) => {
            if (node.children) {
              node.children = addSelectedToData(node.children, filter_ids)
            }
            return node
          }) ?? []) as any[]
        },
      }
    )
    const [search, setSearch] = useState('')
    const [treeData, setTreeData] = useState<any[]>([])
    const [tree, setTree] = useState<TreeApi<Data> | null | undefined>(null)

    useEffect(() => {
      if (!data) return
      setTreeData(data)
    }, [data])

    useDidUpdate(() => {
      if (filter_ids.length === 0) return

      const newTreeData: any[] =
        data?.map((node) => {
          if (node.children) {
            node.children = addSelectedToData(node.children, filter_ids)
          }
          return node
        }) ?? []

      setTimeout(() => {
        setTreeData(newTreeData)
      }, 400)
    }, [data, filter_ids])

    const [count, setCount] = useState(0)

    useEffect(() => {
      setCount(tree?.visibleNodes.length ?? 0)
    }, [search, treeData, tree])

    return (
      <div>
        <TextInput
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search..."
          leftSection={<SearchMd className="ml-1 size-4" />}
        />
        {isLoading ? (
          <DataLoader />
        ) : (treeData ?? []).length === 0 ? (
          search ? (
            <p className="py-2 text-xs text-primary-500">
              No results found. Please check your spelling or try another search.
            </p>
          ) : (
            <p className="py-2 text-xs text-primary-500">
              There are not enough products on e-tailers that match your search criteria under this
              filter
            </p>
          )
        ) : (
          <Tree
            data={treeData}
            //   ref={treeRef}
            ref={(t) => setTree(t)}
            openByDefault={false}
            padding={15}
            rowHeight={32}
            indent={INDENT_STEP}
            overscanCount={8}
            width={'100%'}
            searchTerm={search}
            onSelect={onSelect}
            height={count > 0 ? (count + 1) * 32 : 10}
            onToggle={() => {
              setTimeout(() => {
                setCount(tree?.visibleNodes.length ?? 0)
              })
            }}
            className="!overflow-x-hidden"
          >
            {Node}
          </Tree>
        )}
        {count === 0 && search && (
          <div className="space-y-3 py-2 text-xs text-primary-500">
            <p className=" ">
              No results found under this name. This search is only looking into the category tree
              and filters within the selected main category. If you can&apos;t find what you are
              looking for, we recommend going through the category tree.
            </p>
            <p>If you still need help please watch our tutorial on how to use this feature.</p>
            <div>
              <a
                href="https://eyva.notion.site/How-to-use-category-tree-filters-templates-AI-filters-b27c5b21e56a4bd5a57ab5cec588a7a2"
                target="_blank" // Add this attribute to open in a new tab
                rel="noopener noreferrer"
                className="text-xs text-accent-600 underline-offset-2 hover:underline"
              >
                Learn how to use this feature &rarr;{' '}
              </a>
            </div>
          </div>
        )}
      </div>
    )
  },
  {
    fallback: <ErrorFallback title="Categories & Product Types" />,
  }
)

export const IngredientGroups = Sentry.withErrorBoundary(
  () => {
    const { filter_ids, operator, setOperator } = useTrackedMIStore()
    const vars = useTrackedFilterStore()
    const { advancedMode } = useTrackedSettings()

    const onSelect = useSelect({ childOnly: true })
    const [openedNodes, setOpenedNodes] = useState<string[]>([])
    const [search, setSearch] = useState('')
    const debouncedQuery = useDebounce(search, 200)

    const { data, isLoading, isFetching } = trpc.ecom_filters.ingredient_groups.useQuery(
      {
        ...omitFilterType(vars, 'ingredients'),
        ...(debouncedQuery && { q: debouncedQuery }),
        ...(filter_ids.length > 0 && {
          selected_ids: filter_ids.filter((val) => val.type === 'ingredient').map((o) => o.id),
        }),
      },
      {
        keepPreviousData: true,
        select(data) {
          return (data?.tree?.map((node) => {
            if (node.children) {
              const mergedData = mergeData(data?.selected_ids ?? [], filter_ids)
              node.children = addSelectedToData(node.children, mergedData)
            }
            return node
          }) ?? []) as any[]
        },
      }
    )

    // const [tree, setTree] = useState<TreeApi<Data> | null | undefined>(null)
    const treeRef = useRef<TreeApi<Data> | null | undefined>()

    const [count, setCount] = useState(0)

    useDidUpdate(() => {
      const tree = treeRef.current
      if (!tree) return
      setTimeout(() => {
        openedNodes.forEach((id) => {
          tree.open(id)
        })
      }, 200)
    }, [data])

    useEffect(() => {
      const tree = treeRef.current
      if (!tree) return
      setCount(tree?.visibleNodes.length ?? 0)
    }, [data])

    return (
      <div>
        {advancedMode && (
          <Radio.Group
            value={operator['ingredient']}
            onChange={(val) => setOperator('ingredient', val as 'AND' | 'OR')}
            size="xs"
            className="mb-4"
          >
            <div className="flex space-x-6">
              <Tooltip
                label='Use "or" when you want to broaden the search to include ingredients that meet at least one of the specified criteria.'
                w={200}
                refProp="rootRef"
                position="right"
              >
                <Radio value="OR" label="OR" size="xs" />
              </Tooltip>
              <Tooltip
                label='Use "and" when you want to narrow down results to ingredients that meet multiple specific criteria simultaneously.'
                w={200}
                refProp="rootRef"
                position="right"
              >
                <Radio value="AND" label="AND" size="xs" />
              </Tooltip>
            </div>
          </Radio.Group>
        )}
        <TextInput
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search..."
          leftSection={<SearchMd className="ml-1 size-4" />}
          rightSection={isFetching ? <Loader className="size-4 border-2" /> : null}
        />
        {isLoading ? (
          <DataLoader />
        ) : (data ?? []).length === 0 ? (
          search ? (
            <p className="py-2 text-xs text-primary-500">
              No results found. Please check your spelling or try another search.
            </p>
          ) : (
            <p className="py-2 text-xs text-primary-500">
              There are not enough products on e-tailers that match your search criteria under this
              filter.
            </p>
          )
        ) : (
          <Tree
            data={data}
            ref={treeRef}
            idAccessor={(data) => `${data._id}`}
            openByDefault={!!debouncedQuery}
            padding={15}
            rowHeight={32}
            indent={INDENT_STEP}
            overscanCount={8}
            width={'100%'}
            onSelect={onSelect}
            className="!overflow-x-hidden"
            height={count > 0 ? (count + 1) * 32 : 400}
            // initialOpenState={openedNodes.reduce((acc, id) => {
            //   acc[id] = true
            //   return acc
            // }, {} as Record<string, boolean>)}
            onToggle={(id) => {
              setTimeout(() => {
                setCount(treeRef.current?.visibleNodes.length ?? 0)
              })
              // const index = data?.find((d) => d._id === id)?.id
              setOpenedNodes((prev) => xor(prev, [id]))
            }}
          >
            {IngredientNode}
          </Tree>
        )}
      </div>
    )
  },
  {
    fallback: <ErrorFallback title="Ingredient Groups" />,
  }
)

export type FilterOptions =
  | 'brands'
  | 'ingredients'
  | 'effects'
  | 'attributes'
  | 'concerns'
  | 'certificates'
  | 'sizes'
  | 'etailers'

export const Filter = Sentry.withErrorBoundary(
  ({ type }: { type: FilterOptions }) => {
    const posthog = usePostHog()
    const { filter_ids, toggleFilters } = useTrackedMIStore()
    const vars = useTrackedFilterStore()

    const ref = React.useRef<HTMLDivElement | null>(null)
    const [search, setSearch] = useState('')
    const debouncedQuery = useDebounce(search, 200)

    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage, isFetching } =
      trpc.ecom_filters.filters.useInfiniteQuery(
        {
          type,
          ...omitFilterType(vars, type),
          limit: 20,
          ...(filter_ids.length > 0 && {
            selected_ids: filter_ids
              .filter((val) => val.type === invertedFiltersMap[type][0])
              .map((o) => ({ id: o.id })),
          }),
          ...(debouncedQuery && { q: debouncedQuery }),
        },
        {
          getNextPageParam: (lastPage) => lastPage.nextCursor,
          initialCursor: 1,
          keepPreviousData: true,
        }
      )

    // show selected items in all rows first
    const rows = useMemo(() => {
      const allRows = data ? data.pages.flatMap((d) => d.items) : []
      const selected = mergeData(data?.pages?.[0]?.selected_ids ?? [], filter_ids)
      const unselected = differenceBy(allRows, selected, (o) => o.id)
      return [...selected, ...unselected]
    }, [data, filter_ids])

    // The virtualizer
    const rowVirtualizer = useVirtualizer({
      count: rows.length,
      getScrollElement: () => ref.current,
      estimateSize: () => 34,
      overscan: 13,
    })

    const items = rowVirtualizer.getVirtualItems()

    return (
      <div className="space-y-2">
        <TextInput
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search..."
          leftSection={<SearchMd className="ml-1 size-4" />}
          rightSection={isFetching ? <Loader className="size-4 border-2" /> : null}
        />
        <div className={classNames('w-full')} ref={ref}>
          {isLoading ? (
            <DataLoader />
          ) : items.length === 0 ? (
            search ? (
              <p className="py-2 text-xs text-primary-500">
                No results found. Please check your spelling or try another search.
              </p>
            ) : (
              <p className="py-2 text-xs text-primary-500">
                There are not enough products on e-tailers that match your search criteria under
                this filter
              </p>
            )
          ) : (
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {items?.map((virtualRow, index) => {
                const data = rows[virtualRow.index]
                return (
                  <div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                    key={virtualRow.index}
                    className="flex items-center space-x-2"
                  >
                    <Checkbox
                      size="xs"
                      checked={filter_ids.findIndex((o) => o.id === data.id) > -1}
                      onChange={() => {
                        toggleFilters([{ id: data.id, name: data.name, type: data.id_type }])
                        posthog?.capture(`${FILTERS_MAP[data.id_type]} Filter Toggled`, {
                          id: data.id,
                          name: data.name,
                          type: data.id_type,
                        })
                      }}
                      label={
                        <p className={'cursor-pointer text-xs hover:text-accent-600'}>
                          {type === 'etailers' ? prepEtailerName(data.id) : data.name}{' '}
                          <span className="text-gray-600">({formatNumberCompact(data.count)})</span>
                        </p>
                      }
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
        {hasNextPage && (
          <Button loading={isFetchingNextPage} onClick={() => fetchNextPage()} variant="subtle">
            Load More
          </Button>
        )}
      </div>
    )
  },
  {
    fallback: ({ error, componentStack, resetError }) => {
      console.error({ error, componentStack })
      return <ErrorFallback title="Filters" />
    },
  }
)
export const ForWhomFilter = Sentry.withErrorBoundary(
  () => {
    const posthog = usePostHog()
    const { filter_ids, setForWhomFilter } = useTrackedMIStore()
    const vars = useTrackedFilterStore()

    const { data: items, isLoading } = trpc.ecom_filters.for_whom_filter.useQuery(
      omitFilterType(vars, 'For Whom')
    )
    if (isLoading) return <DataLoader height={115} />

    return (
      <Radio.Group
        value={filter_ids.find((f) => f.type === 'for_whom')?.id ?? 'for_all'}
        onChange={(val) => {
          const selected = items?.items.find((d) => d.id === val)
          if (!selected) return
          setForWhomFilter({
            id: selected.id,
            name: selected.name,
            type: selected.id_type,
          })
          posthog?.capture(`${selected.id_type} Filter Toggled`, {
            id: selected.id,
            name: selected.name,
            type: selected.id_type,
          })
        }}
        className="!mb-2 ml-2"
      >
        <div className="space-y-4">
          {items?.items.map((data) => {
            const displayName = data.name === 'Woman/Unixsex' ? 'Unisex' : data.name
            return (
              <Radio
                key={data.id}
                size="xs"
                value={data.id}
                label={
                  <p className="text-xs">
                    {displayName}{' '}
                    <span className="text-gray-600">({formatNumberCompact(data.count)})</span>
                  </p>
                }
              />
            )
          })}
        </div>
      </Radio.Group>
    )
  },
  {
    fallback: <ErrorFallback title="Filters" />,
  }
)

const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // strokeLinecap="round"
    // strokeLinejoin="round"
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <linearGradient id="a" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#000" stopOpacity={1} />
        <stop offset="100%" stopColor="#fff" stopOpacity={1} />
      </linearGradient>
    </defs>
    <path d="m12 17.75-6.172 3.245 1.179-6.873-5-4.867 6.9-1 3.086-6.253 3.086 6.253 6.9 1-5 4.867 1.179 6.873z" />
  </svg>
)

export const RatingsFilters = Sentry.withErrorBoundary(
  () => {
    const posthog = usePostHog()

    const { filter_ids, toggleFilters } = useTrackedMIStore()
    const vars = useTrackedFilterStore()

    const { data: ratings, isLoading } = trpc.ecom_filters.filters.useQuery({
      type: 'ratings',
      ...omitFilterType(vars, 'ratings'),
      limit: 50,
    })
    if (isLoading) return <DataLoader height={135} />

    if ((ratings?.items ?? []).length === 0)
      return (
        <p className="py-2 text-xs text-primary-500">
          There are not enough products on e-tailers that match your search criteria under this
          filter
        </p>
      )

    return (
      <div className=" mb-2 space-y-4">
        {ratings?.items.map((data) => {
          const i = parseInt(data.name)
          return (
            <Checkbox
              key={i}
              size="xs"
              checked={filter_ids.findIndex((o) => o.id === data.id) > -1}
              onChange={() => {
                toggleFilters([{ id: data.id, name: `rating: ${i}-${i + 1}`, type: data.id_type }])
                posthog?.capture(`${FILTERS_MAP[data.id_type]} Filter Toggled`, {
                  id: data.id,
                  name: `rating: ${i}-${i + 1}`,
                  type: data.id_type,
                })
              }}
              label={
                <div
                  className={classNames(
                    `-mt-1 flex items-center space-x-2`,
                    { 2: 'gradient-2', 3: 'gradient-3', 4: 'gradient-4', 5: 'gradient-5' }[i + 1]
                  )}
                >
                  <Rating
                    value={i}
                    readOnly
                    color={'dark'}
                    fullSymbol={<StarIcon className="size-5 text-black" />}
                    emptySymbol={<StarIcon className="size-5 text-gray-300" />}
                  />
                  <p className="text-xs">
                    {`${i}-${i + 1}`}{' '}
                    <span className="text-gray-600">({formatNumberCompact(data.count)})</span>
                  </p>
                </div>
              }
            />
          )
        })}
      </div>
    )
  },
  {
    fallback: <ErrorFallback title="Filters" />,
  }
)

export const PriceRangeFilter = () => {
  const posthog = usePostHog()

  const { filter_ids, addFilters } = useTrackedMIStore()
  const vars = useTrackedFilterStore()

  const [min_price, max_price] = filter_ids.find((f) => f.id === 'price')?.value ?? [0, 10000]
  const [rangeValue, setRangeValue] = useState<[number, number]>([min_price, max_price])
  const currency = getCurrency[vars.geo].symbol
  useEffect(() => {
    setRangeValue([min_price, max_price])
  }, [min_price, max_price])
  return (
    <div className="space-y-5 p-2">
      <RangeSlider
        min={0}
        max={10000}
        step={1}
        size="xs"
        thumbSize={16}
        styles={{ thumb: { borderWidth: '2px' } }}
        label={null}
        value={rangeValue}
        onChange={setRangeValue}
        onChangeEnd={(value) => {
          setRangeValue(value)
          addFilters([
            {
              id: 'price',
              name: `${currency}${value[0]} - ${currency}${value[1]}`,
              type: 'price',
              value: [value[0], value[1]],
            },
          ])
          posthog?.capture(`Price Filter Changed`, {
            id: 'price',
            name: `${currency}${value[0]} - ${currency}${value[1]}`,
            type: 'price',
            value: [value[0], value[1]],
          })
        }}
      />

      <div className="flex items-center justify-between space-x-3">
        <NumberInput
          hideControls
          leftSection={currency}
          min={0}
          value={min_price}
          onChange={(e: any) => {
            if (e !== '') {
              addFilters([
                {
                  id: 'price',
                  name: `${currency}${e} - ${currency}${max_price}`,
                  type: 'price',
                  value: [e, max_price],
                },
              ])
              posthog?.capture(`Price Filter Changed`, {
                id: 'price',
                name: `${currency}${e} - ${currency}${max_price}`,
                type: 'price',
                value: [e, max_price],
              })
            }
          }}
          placeholder="Min"
        />
        <p>-</p>
        <NumberInput
          hideControls
          leftSection={currency}
          min={min_price}
          value={max_price}
          onChange={(e: any) =>
            e !== '' &&
            addFilters([
              {
                id: 'price',
                name: `${currency}${min_price} - ${currency}${e}`,
                type: 'price',
                value: [min_price, e],
              },
            ])
          }
          placeholder="Max"
        />
      </div>
    </div>
  )
}

export const FilterChip = ({
  children,
  onClose,
}: {
  children: React.ReactNode
  onClose?: () => void
}) => {
  return (
    <div className="m-1 flex items-center space-x-2 rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-xs">
      <p>{children}</p>
      {onClose && (
        <button onClick={onClose}>
          <XIcon className="h-4 w-3" />
        </button>
      )}
    </div>
  )
}

export const SelectedFilters = () => {
  const { filter_ids, etailers, toggleFilters, setFilters } = useTrackedMIStore()
  if (filter_ids.length === 0) return null

  return (
    <div className="flex items-start justify-between space-x-2">
      <div className="-m-1 flex flex-wrap items-center">
        {filter_ids.map((d) => (
          <FilterChip
            key={d.id}
            onClose={() => {
              toggleFilters([d])
            }}
          >
            {d.name}
          </FilterChip>
        ))}
        {etailers.map((d) => (
          <FilterChip
            key={d}
            onClose={() => {
              setFilters((state) => {
                state.etailers = xor(state.etailers, [d])
              })
            }}
          >
            {prepEtailerName(d)}
          </FilterChip>
        ))}
      </div>
    </div>
  )
}

export const isSelectedFiltersPopoverOpenedAtom = atom(false)

export const SelectedFiltersPopover = () => {
  const [opened, setOpened] = useAtom(isSelectedFiltersPopoverOpenedAtom)
  const [modalOpened, setModalOpened] = useState(false)
  const { filter_ids, toggleFilters, resetFilters } = useTrackedMIStore()

  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null)
  const [control, setControl] = useState<HTMLButtonElement | null>(null)
  const [sidebar, setSidebar] = useAtom(sidebarRefAtom)
  const [searchParams, setSearchParams] = useSearchParams()
  const shouldOpen = searchParams?.get('showSelectedFilters')

  useClickOutside(() => setOpened(false), null, [control, dropdown, sidebar])
  useEffect(() => {
    if (shouldOpen) {
      setOpened(true)
      // dont open on next render
      searchParams?.delete('showSelectedFilters')
      setSearchParams(searchParams)
    }
  }, [])

  //   useDidUpdate(() => {
  //     if (filter_ids.length === 0 || opened) return
  //     setOpened(true)
  //   }, [filter_ids])

  //   if (filter_ids.length === 0) return null
  const data = groupBy(filter_ids, (o) => FILTERS_MAP[o.type])
  return (
    <div>
      <SaveTemplatesModal opened={modalOpened} setOpened={setModalOpened} />
      <Popover
        opened={opened}
        onChange={setOpened}
        width={500}
        position="bottom-end"
        zIndex={200}
        shadow="md"
        closeOnClickOutside={false}
        disabled={filter_ids.length === 0}
      >
        <Popover.Target>
          <Tooltip
            label={
              filter_ids.length === 0
                ? 'Please select some filters.'
                : 'Click to view selected filters.'
            }
            position="bottom-end"
          >
            <button
              className="flex items-center space-x-1 rounded-md bg-white p-2 text-xs"
              onClick={() => setOpened((o) => !o)}
              ref={setControl}
            >
              <ListFilter className="-mt-0.5 size-4" /> <p>({filter_ids.length})</p>
            </button>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <div className="relative p-4" ref={setDropdown}>
            <button
              className="absolute right-3 top-3 !mt-0 text-xs font-medium underline underline-offset-2"
              onClick={() => resetFilters()}
            >
              Remove all
            </button>
            <div className="space-y-4">
              {uniq(Object.values(FILTERS_MAP)).map((filterType) => {
                const filters = data?.[filterType] ?? []
                // create object with count of every item in the filters
                const count = filters.reduce((acc, curr) => {
                  acc[curr.name] = (acc[curr.name] || 0) + 1
                  return acc
                }, {} as Record<string, number>)
                if (filters.length > 0)
                  return (
                    <div key={filterType} className="space-y-2">
                      <h2 className="text-xs font-medium capitalize">{filterType}</h2>
                      <div className="-m-1 flex flex-wrap items-center">
                        {filters.map((d) => {
                          return (
                            <FilterChip key={d.id} onClose={() => toggleFilters([d])}>
                              {d.name}{' '}
                              {count[d.name] > 1 && (
                                <span className="font-medium text-primary-500">
                                  ({d.parentName})
                                </span>
                              )}
                            </FilterChip>
                          )
                        })}
                      </div>
                    </div>
                  )
              })}
            </div>
            <div className="flex items-end justify-end">
              <Button
                onClick={() => setModalOpened(true)}
                variant="subtle"
                size="xs"
                className="!mt-3 !text-accent-600"
              >
                Save your filters
              </Button>
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}
