// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/components`
  | `/consumer-insights`
  | `/consumer-insights-v2`
  | `/consumer-insights/search`
  | `/discovery`
  | `/feedback`
  | `/feedback/Feedback`
  | `/ingredients`
  | `/instagram`
  | `/login`
  | `/login/oauth`
  | `/market-insights`
  | `/new-products`
  | `/news`
  | `/password`
  | `/password/update`
  | `/pre-ingredients`
  | `/product-analysis`
  | `/product-comparison/:id`
  | `/product-details/:p_c_id`
  | `/product-insights`
  | `/rankings`
  | `/register`
  | `/register/company`
  | `/register/confirm-email`
  | `/register/create-profile`
  | `/register/email-sent`
  | `/register/invite`
  | `/report-insights`
  | `/report-insights/:insights`
  | `/reports`
  | `/reports/:report`
  | `/request-reports`
  | `/review-insights`
  | `/userProfile`
  | `/userProfile/Company`
  | `/userProfile/profiles-permissions`

export type Params = {
  '/product-comparison/:id': { id: string }
  '/product-details/:p_c_id': { p_c_id: string }
  '/report-insights/:insights': { insights: string }
  '/reports/:report': { report: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
