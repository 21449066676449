import React from 'react'
import { CogIcon } from '@heroicons/react/solid'
import { Popover, Switch, Tooltip } from '@mantine/core'

import { useTrackedSettings } from '../_hooks/useGlobalSettings'

export const SettingsMenu = () => {
  const { advancedMode, setAdvancedMode } = useTrackedSettings()
  return (
    <Popover width={350} position="bottom-start" withArrow shadow="md">
      <Popover.Target>
        <Tooltip
          label={
            <div className="space-y-1">
              <p className="font-semibold">Advanced Mode</p>
              <p>
                Please click here to activate additional functionalities that are not available in
                the standard interface.
              </p>
            </div>
          }
          position="bottom"
          w={230}
        >
          <button className="rounded-md bg-white p-2">
            <CogIcon className="h-5 w-5" />
          </button>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown px={10} py={8}>
        <label
          className="flex w-full cursor-pointer items-start justify-between space-x-4 rounded-md px-3 py-2 hover:bg-gray-100"
          htmlFor="advancedMode"
        >
          <div className="space-y-1">
            <p className="font-medium text-primary-900">Advanced Mode</p>
            <p className="text-xs font-normal text-primary-700">
              This mode offers enhanced functionalities not found in the standard interface, such as
              option to refine <b>ingredient filters</b> with{' '}
              <b>&quot;and/or&quot; options for greater flexibility.</b>
            </p>
          </div>
          <Switch
            id="advancedMode"
            className="mt-2"
            size="sm"
            checked={advancedMode}
            onChange={(event) => setAdvancedMode(event.currentTarget.checked)}
          />
        </label>
      </Popover.Dropdown>
    </Popover>
  )
}
