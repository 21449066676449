import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { Tooltip, TooltipProps } from '@mantine/core'
import ReactTooltipDefault, { TooltipProps as ReactTooltipProps } from 'react-tooltip'

export const ReactTooltip: React.FunctionComponent<React.PropsWithChildren<ReactTooltipProps>> = ({
  id,
  children,
  ...rest
}) => {
  return (
    <>
      <ReactTooltipDefault
        id={id}
        className="max-w-sm text-sm font-medium"
        type="dark"
        backgroundColor="#D1E0FF"
        borderColor="#0E4CC3"
        textColor="black"
        border
        place="right"
        {...rest}
      >
        {children}
      </ReactTooltipDefault>
    </>
  )
}

export const InfoTooltip: React.FunctionComponent<React.PropsWithChildren<ReactTooltipProps>> = ({
  id,
  children,
  ...rest
}) => {
  return (
    <>
      <span data-for={id} data-tip>
        <InformationCircleIcon className="h-4 w-4 text-primary-900" />
      </span>
      <ReactTooltip id={id} {...rest}>
        <p className="text-left">{children}</p>
      </ReactTooltip>
    </>
  )
}

export const ShouldShowTooltip = ({
  children,
  show,
  ...rest
}: { children: React.ReactNode; show: boolean } & TooltipProps) => {
  return show ? <Tooltip {...rest}>{children}</Tooltip> : <>{children}</>
}
