import { useContext } from 'react'
import { Accordion, ScrollArea } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { atom, useSetAtom } from 'jotai'
import { Outlet } from 'react-router-dom'

import { ErrorFallback } from '@/components/ErrorFallback'
import { TopBarEcom } from '../_components/TopBar'
import { CategoryTabs } from './_components/CategoryTabs'
import {
  CategoryTree,
  Filter,
  ForWhomFilter,
  IngredientGroups,
  isSelectedFiltersPopoverOpenedAtom,
  PriceRangeFilter,
  RatingsFilters,
} from './_components/Filters'
import { SaveFilterModal } from './_components/SaveFilterPrompt'
import { SelectTemplates } from './_components/Templates'
import { RankingFilterContext, useTrackedMIStore } from './_hook'

export const sidebarRefAtom = atom<HTMLDivElement | null>(null)

const accordionMap = [
  {
    title: 'Categories & Product Types',
    content: () => <CategoryTree />,
  },
  {
    title: 'Ingredients',
    content: () => <IngredientGroups />,
  },
  {
    title: 'For Whom',
    content: () => <ForWhomFilter />,
  },
  {
    title: 'Etailers',
    content: () => <Filter type="etailers" />,
  },
  {
    title: 'Brands',
    content: () => <Filter type="brands" />,
  },
  {
    title: 'Effects',
    content: () => <Filter type="effects" />,
  },
  {
    title: 'Attributes',
    content: () => <Filter type="attributes" />,
  },
  {
    title: 'Concerns',
    content: () => <Filter type="concerns" />,
  },
  {
    title: 'Certificates',
    content: () => <Filter type="certificates" />,
  },
  {
    title: 'Sizes',
    content: () => <Filter type="sizes" />,
  },
  {
    title: 'Ratings',
    content: () => <RatingsFilters />,
  },
  {
    title: 'Price Range',
    content: () => <PriceRangeFilter />,
  },
]

export const Layout = () => {
  const setOpened = useSetAtom(isSelectedFiltersPopoverOpenedAtom)
  const setSidebar = useSetAtom(sidebarRefAtom)
  const { c2Id } = useTrackedMIStore()

  const rankingFilter = useContext(RankingFilterContext)

  return (
    <>
      <SaveFilterModal />
      <TopBarEcom />
      <CategoryTabs />
      <div className="max-w-layout mt-2 flex text-sm">
        <ScrollArea
          h={'calc(100vh - 215px)'}
          scrollbarSize={8}
          scrollHideDelay={150}
          className="shrink-0 basis-[285px] py-2 pr-5"
          onMouseEnter={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
          ref={setSidebar}
        >
          <div className="space-y-4 p-1">
            <a
              href="https://eyva.notion.site/How-to-use-category-tree-filters-templates-AI-filters-b27c5b21e56a4bd5a57ab5cec588a7a2"
              target="_blank" // Add this attribute to open in a new tab
              rel="noopener noreferrer"
              className="text-xs text-accent-600 underline-offset-2 hover:underline"
            >
              Learn how to use this feature &rarr;{' '}
            </a>
            <div>
              <SelectTemplates />
              {/* <AIFilters /> */}
            </div>
            {/* <h3 className="text-xs font-medium">Categories & Product Types</h3> */}
          </div>

          <Accordion
            defaultValue={'Categories & Product Types'}
            px={0}
            styles={{
              label: { fontSize: '12px', fontWeight: 500, padding: 0 },
              control: {
                padding: '12px 0',
              },
              content: {
                padding: '0 0 12px',
              },
            }}
            classNames={{ control: 'hover:!bg-transparent' }}
          >
            {accordionMap
              .filter((val) => (rankingFilter?.rankings ? !['Etailers'].includes(val.title) : true))
              .map((item) => (
                <Accordion.Item value={item.title} key={item.title}>
                  <Accordion.Control className="capitalize">{item.title}</Accordion.Control>
                  <Accordion.Panel key={c2Id}>{item.content()}</Accordion.Panel>
                </Accordion.Item>
              ))}
          </Accordion>
        </ScrollArea>
        <div
          className="h-[calc(100vh-215px)] grow overflow-y-auto px-5 pb-10 pt-16"
          id="scrollable"
        >
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default Sentry.withErrorBoundary(Layout, {
  fallback: <ErrorFallback title="Market Insights" className="max-w-layout m-auto" />,
})
