export const IS_DEV = import.meta.env.VITE_ENVIROMENT === 'DEV'
export const IS_PROD = import.meta.env.VITE_ENVIROMENT === 'PROD'
export const RESEARCH_LINK = IS_DEV ? 'https://search-dev.eyva.ai/' : 'https://search.eyva.ai/'

export const ETAILERS = {
  de: [
    'dm_de',
    'douglas_de',
    'flaconi_de',
    'net_a_porter_de',
    'niche_beauty_de',
    'notino_de',
    'rossmann_de',
    'sephora_de',
    'mueller_de',
  ],
  us: ['niche_beauty_com', 'sephora_com', 'ulta_com', 'net_a_porter_com'],
  uk: ['notino_uk', 'net_a_porter_uk', 'niche_beauty_uk', 'sephora_uk'],
}

export const REVIEWS_ETAILERS = {
  de: ['dm_de', 'douglas_de', 'flaconi_de', 'rossmann_de', 'sephora_de', 'mueller_de'],
  us: ['sephora_com', 'ulta_com'],
  uk: ['sephora_uk'],
}

export const CATEGORIES = [
  { c2_id: '-3041563466726729621', label: 'Skincare', img: '/categories/webp/skin care.webp' },
  { c2_id: '-8927773384010863663', label: 'Body', img: '/categories/webp/body.webp' },
  { c2_id: '-5260893170826292991', label: 'make up', img: '/categories/webp/make up.webp' },
  { c2_id: '3196609641275568313', label: 'hair', img: '/categories/webp/hair care.webp' },
  { c2_id: '-6608966659828261852', label: 'fragrance', img: '/categories/webp/fragrance.webp' },
  { c2_id: '-401414836000200262', label: 'Health', img: '/categories/webp/health.webp' },
  { c2_id: '3172394317186079035', label: 'Grooming', img: '/categories/webp/grooming.webp' },
  { c2_id: '7514492746299008347', label: 'Kids & Baby', img: '/categories/webp/kids.webp' },
  { c2_id: '1697101705461460363', label: 'sets', img: '/categories/webp/sets.webp' },
]

export const CI_CATEGORIES = [
  { c2_id: '3914228190679554989', label: 'skin care', img: '/categories/webp/skin care.webp' },
  { c2_id: '-5616082504844587515', label: 'bath & body', img: '/categories/webp/body.webp' },
  { c2_id: '-1856098516420443798', label: 'make up', img: '/categories/webp/make up.webp' },
  { c2_id: '-4299898496507070167', label: 'hair care', img: '/categories/webp/hair care.webp' },
  { c2_id: '3880378166348282584', label: 'fragrance', img: '/categories/webp/fragrance.webp' },
  { c2_id: '-1109885545695339203', label: 'nail care', img: '/categories/webp/nail care.webp' },
]

export const EXPORT_ROWS = 1000
